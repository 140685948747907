@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

@font-face {
    font-family: "Caveat-fallback";
    size-adjust: 80%;
    src: local("Arial Narrow Italic");
}

@font-face {
    font-family: "Caveat-fallback-2";
    size-adjust: 76.14%;
    src: local("Arial");
}

@font-face {
    font-family: "Caveat-fallback-3";
    size-adjust: 76.14%;
    src: local("Helvetica");
}

@layer base {
    :root {
        --color-white: 255 255 255;
        --color-black: 15 27 61;
        --color-gray-800: 39 50 80;
        --color-gray-600: 87 95 119;
        --color-gray-400: 135 141 158;
        --color-gray-200: 183 187 197;
        --color-gray-150: 205 205 205;
        --color-gray-100: 231 237 238;
        --color-gray-50: 249 249 249;

        --color-primary: 236 11 45;
        --color-primary-dark: 205 10 39;
        --color-blue: 8 85 157;
        --color-blue-light: 242 248 253;
        --color-blue-gray: 146 174 199;
        --color-blue-bright: 0 111 214;
        --color-green: 57 194 118;
        --color-yellow: 254 200 43;

        --arc-bottom-svg: url("data:image/svg+xml,%3Csvg width='1440' height='640' viewBox='0 0 1440 640' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0 0H1440V615C1440 615 1001.46 640 720 640C438.545 640 0 615 0 615V0Z' fill='%235200FF'/%3E%3C/svg%3E");
        --arc-bottom-mobile-svg: url("data:image/svg+xml,%3Csvg id='arc-mask-mobile' viewBox='0 0 318 598' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0 566s94.587 32 158.5 32S318 566 318 566V0H0v566Z' fill='%230F1B3D'/%3E%3C/svg%3E");
    }

    html {
        @apply antialiased font-normal text-base text-black;
    }
}

@layer utilities {
    .heading {
        @apply font-bold leading-tight;
    }

    .paragraph {
        @apply font-normal leading-normal;
    }

    .container {
        @apply px-4 mx-auto lg:max-w-[1440px] lg:px-6;
    }

    .container-md {
        @apply px-4 mx-auto max-w-6xl lg:px-6;
    }

    .container-sm {
        @apply px-4 mx-auto max-w-5xl lg:px-6;
    }

    .container-xs {
        @apply px-4 mx-auto max-w-3xl lg:px-6;
    }

    .card-gradient {
        background: linear-gradient(0deg,
                rgb(var(--color-black)) 5%,
                rgb(4 14 45 / 0) 90%);
    }

    .heading-gradient-sm {
        background: linear-gradient(0deg,
                rgb(var(--color-black) / 0.5) 0%,
                rgb(var(--color-black) / 0) 100%);
    }

    .heading-gradient {
        background: linear-gradient(0deg,
                rgb(var(--color-black)) 0%,
                rgb(var(--color-black) / 0) 60%);
    }

    .heading-gradient-lg {
        background: linear-gradient(0deg,
                rgb(var(--color-black)) 10%,
                rgb(var(--color-black) / 0) 100%);
    }

    .heading-gradient-xl {
        background: linear-gradient(0deg,
                rgb(var(--color-black)) 10%,
                rgb(var(--color-black) / 0) 100%);
    }

    .has-bottom-arc {
        mask-image: var(--arc-bottom-mobile-svg);
        mask-size: cover;
        mask-repeat: no-repeat;
        mask-position: bottom;
        -webkit-mask-image: var(--arc-bottom-mobile-svg);
        -webkit-mask-size: cover;
        -webkit-mask-repeat: no-repeat;
        -webkit-mask-position: bottom;
    }

    @screen md {
        .has-bottom-arc {
            mask-image: var(--arc-bottom-svg);
            -webkit-mask-image: var(--arc-bottom-svg);
        }
    }

    .progress-bar {
        z-index: @apply z-progress-bar;
    }

    .skeleton {
        @apply bg-gradient-to-r from-gray-150 to-gray-100 animate-pulse opacity-20;
    }

    .skeleton-text {
        @apply bg-gradient-to-r from-gray-100 to-gray-400 animate-pulse opacity-20 bg-clip-text text-transparent;
    }


    .dots:after {
        content: '';
        animation: progress-ellipsis 3s infinite;
        width: 1rem;
        display: inline-block;
    }

    @keyframes progress-ellipsis {
        0% {
            content: '';
        }

        30% {
            content: '.';
        }

        60% {
            content: '..';
        }

        90% {
            content: '...';
        }
    }

    .curve-parent {
        width: calc(100% + 4rem);
        @apply relative rounded-b-[50%] -left-8 overflow-hidden will-change-transform;
    }

    .curve-child {
        width: calc(100% - 4rem);
        @apply left-8;
    }

    .perspective-full {
        perspective: 100vw;
    }

    .preserve-3d {
        transform-style: preserve-3d;
    }

    /* Hide scrollbar for Chrome, Safari and Opera */
    .no-scrollbar::-webkit-scrollbar {
        display: none;
    }

    /* Hide scrollbar for IE, Edge and Firefox */
    .no-scrollbar {
        /* Firefox */
        scrollbar-width: none;
        /* IE and Edge */
        -ms-overflow-style: none;
    }
}

/* Reset mapbox popup styling */
.mapboxgl-popup-tip {
    display: none;
}

.mapboxgl-popup .mapboxgl-popup-content {
    background: none;
    box-shadow: none;
    padding: 0;
    border-radius: 0;
}

.grecaptcha-badge {
    visibility: hidden;
}